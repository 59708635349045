<template>
    <main id="home-about" class="page">
        <section class="jumbotron jumbotron-fluid bg-jumbotron">
            <div class="container">
                <h1 class="display-4 mb-4">关于我们</h1>
                <p class="lead">京禧投资欢迎您</p>
            </div>
        </section>
        <article class="container my-5 markdown" v-html="content"></article>
    </main>
</template>

<script>
import content from '@a/about/content.md';

export default {
    name: 'about',
    data() {
        return {
            content
        };
    }
};
</script>

<style lang="less">
#home-about {
    .bg-jumbotron {
        background-image: url(~@a/about/landing.jpg);
        padding-top: 10rem;
        padding-bottom: 5rem;
    }
}
</style>